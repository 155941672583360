import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'


import AppPageHeader from '@/views/layout/AppPageHeader.vue'
import AppLoader from '@/components/Loader.vue'

import './registerServiceWorker'

import { defaultAppSettings } from '@/helpers/utilities'

Vue.component('app-page-header', AppPageHeader)
Vue.component('app-loader', AppLoader)
Vue.use(PerfectScrollbar)

var CryptoJS = require('crypto-js')

// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth) && (!store.getters.user.access_token || store.getters.user.access_token === '')) {
    return next({
      path: '/prijava',
      query: { redirect: to.fullPath }
    })
  } else if ( (to.name == 'login' || to.name == 'passwordResetRequest' || to.name == 'passwordReset') && store.state.user.access_token) {
    return router.push({
      name: 'dashboard'
    })
  } else if(to.path == '/') {
    return router.push({
      name: 'dashboard'
    })
  } else {
      return next()
  }
})

// Check local storage to handle refreshes
if (window.localStorage) {
  var userToRemove = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY_OLD)) || null

  if(userToRemove) {
    localStorage.removeItem(process.env.VUE_APP_KEY_OLD)
  }

  var oldSettings = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_SETTINGS_OLD)) || null
  if(oldSettings) {
    localStorage.removeItem(process.env.VUE_APP_SETTINGS_OLD)
  }

  var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
  if (user) {
    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    user = decryptedData
    if( (store.state.user.id !== user.id) && (user.access_token && store.state.user.access_token !== user.access_token)) {
      store.commit('SET_USER', user)
    }
    
  }

  let appSettings = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_SETTINGS_KEY)) || null
  if(appSettings) {
    //window.console.log("App settings: ")
    //window.console.log(appSettings)
    store.commit('SET_STATE_APP_SETTINGS', appSettings)

  } else {
    window.console.log("App settings not set.")
    let appSettings = defaultAppSettings();
    store.commit('SET_DEFAULT_APP_SETTINGS', appSettings)

  }
}

//event bus
export const EventBus = new Vue();

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
